.mainDivSection6 {
  display: flex;
  width: 100%;
  padding-top: 20px;
  align-items: center;
  background-color: #f3f8fb;
  justify-content: center;
}

.AboutSection6 {
  display: flex;
  max-width: 1200px;
  width: 100%;
  flex-direction: row;
  height: 500px;
  overflow: hidden;
  padding: 30px;
}

.Aboutsection6Heading {
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 30px;
  justify-content: flex-start;
}

.Aboutsection6Container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid rgba(145, 145, 145, 0.423);
  align-items: center;
  text-align: center;
  justify-content: center;
  width: fit-content;
  max-width: 300px;
  padding: 2%;
  height: 300px;
  margin: 2%;
}

.section6Icons {
  font-size: 50px;
  top: -30px;
  position: absolute;
}

.section6Icons img {
  width: 50px;
  height: 50px;
}

@media (max-width: 1024px) {
  .AboutSection6 {
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 20px;
  }

  .Aboutsection6Heading {
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
  }

  .Aboutsection6Container {
    max-width: 80%;
    margin-top: 18px;
    padding: 3%;
  }

  .section6Icons {
    font-size: 40px;
    top: -20px;
  }

  .section6Icons img {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 768px) {
  .Aboutsection6Container {
    max-width: 90%;
    margin-top: 20px;
    padding: 4%;
    padding-top:30px;
    height: auto;
  }

  .section6Icons {
    font-size: 35px;
    top: -20px;
  }

  .section6Icons img {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 480px) {
  .mainDivSection6 {
    flex-direction: column;
    padding: 2%;
    padding-top: 10px;
  }

  .AboutSection6 {
    padding: 10px;
  }

  .Aboutsection6Heading {
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-bottom: 15px;

  }

  .Aboutsection6Container {
    max-width: 95%;
    padding: 5%;
    padding-top:20px;
    height: auto;
    margin-top: 16px;
  }

  .section6Icons {
    font-size: 30px;
    top: -15px;
  }

  .section6Icons img {
    width: 30px;
    height: 30px;
  }
}
