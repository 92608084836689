.sec5MainDiv {
  position: relative;
  height: 500px;
  background-color: #f2edf5;
  border-bottom: 2px solid #ebebeb;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sec5ImgDiv {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sec5Img {
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 2%;
  height: 60%; 
  max-width: 90%; 
  object-fit: cover; 
}

.sec5Heading {
  text-align: center;
  width: 100%;
  padding: 20px;
}

@media (max-width: 768px) {
  .sec5MainDiv {
    height: auto;
  }
  .sec5Img {
    height: 50%;
  }
  .sec5Heading {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .sec5Img {
    height: 40%;
  }
  .sec5Heading {
    padding: 10px;
  }
}
