.section7mainDiv {
  border-bottom: 1px solid rgba(209, 196, 196, 0.596);
  display: flex;
  padding: 80px 0px;
  align-items: center;
  justify-content: center;
}

.numberDiv {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 20px;
  margin: 2%;
}

.numberContainer {
  display: flex;
  max-width: 1200px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap; 
}

.countingNumber {
  font-weight: 700;
  font-size: 3rem;
  display: flex;
  flex-direction: row;
}

.numberDiv p {
  font-size: 2rem;
  font-weight: 500;
}


@media (max-width: 1024px) {
  .countingNumber {
    font-size: 2.5rem; 
  }
  
  .numberDiv p {
    font-size: 1.75rem; 
  }
  
  .numberDiv {
    padding: 15px; 
    margin: 1.5%;
  }
}

@media (max-width: 768px) {
  .countingNumber {
    font-size: 2rem; 
  }
  
  .numberDiv p {
    font-size: 1.5rem; 
  }
  
  .numberDiv {
    padding: 10px; 
    margin: 1%;
  }
  
  .numberContainer {
    flex-direction: column; 
  }
}

@media (max-width: 480px) {
  .countingNumber {
    font-size: 1.5rem; 
  }
  
  .numberDiv p {
    font-size: 1.25rem; 
  }
  
  .numberDiv {
    padding: 5px; 
    margin: 0.5%;
  }
}
