.sec6MainDiv {
  padding: 50px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #222222;
  border-bottom: 2px solid #ebebeb;
}

.sec6Heading {
  display: flex;
  max-width: 1200px;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  color: white;
  padding: 50px;
  text-overflow: ellipsis;
}

.sec6FormDiv {
  padding: 10px;
  box-sizing: border-box;
}

.sec6Input input,
textarea {
  background-color: transparent !important;
  padding: 10px;
  color: white;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
  border-radius: 2% !important;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 992px) {
  .sec6MainDiv {
    flex-direction: column;
    padding: 20px;
  }
  .sec6Heading {
    padding: 20px;
    align-items: center;
  }
  .sec6FormDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sec6Input input,
  textarea {
    padding: 8px;
    width: 550px;
    border-radius: 5px;
  }
}

@media (max-width: 576px) {
  .sec6Heading {
    padding: 10px;
    text-align: center;
  }
  .sec6Input input,
  textarea {
    max-width: 400px;
    width: 100%;
    padding: 6px;
    border-radius: 3px;
  }
}
