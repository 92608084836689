.MainContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.services-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #c6e1f5;
  border-radius: 5px;
  padding: 20px;
  height: 150px;
  margin-bottom: -50px;
  width: 90vw;
  max-width: 1000px;
  flex-wrap: wrap;
}

.service {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.service-icon {
  font-size: 2rem;
  margin-right: 10px;
}

.service-content {
  text-align: left;
}

.service-content h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  }
  
  .service-content p {
  margin: 5px 0 0;
  font-size: 0.7rem;
  color: #555;
}

.divider {
  height: 50px;
  width: 1px;
  background-color: #ddd;
  margin: 0 20px;
}

@media (max-width: 768px) {
  .service-icon {
    font-size: 1.5rem;
  }

  .service-content h3 {
    font-size: 0.9rem;
  }

  .service-content p {
    font-size: 0.6rem;
  }

  .services-container {
    padding: 10px;
    margin-top: -20px;
  }

  .divider {
    height: 40px;
    margin: 0 10px;
  }
}

@media (max-width: 480px) {
  .service {
    flex-direction: row;
    align-items: center;
  }

  .service-icon {
    font-size: 1.25rem;
  }

  .service-content h3 {
    font-size: 0.8rem;
  }

  .service-content p {
    font-size: 0.55rem;
  }

  .services-container {
    flex-direction: row;
  }
}
