.AboutSection3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section3Img {
  width: 100%;
  height: auto;
  max-width: 1300px;
}

@media (max-width: 1024px) {
  .section3Img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 768px) {
  .AboutSection3 {
    flex-direction: column;
  }

  .section3Img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .AboutSection3 {
  }

  .section3Img {
    width: 100%;
    height: auto;
  }
}
