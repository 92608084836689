.HomeSec4MainContainer {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px 0px;
  justify-content: center;
}
.Sec4MainDiv {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  width: 100%;
}
.sec4Heading {
  max-width: 1200px;
  width: 100%;
  display: flex;
  padding-left: 30px;
  flex-direction: column;
}

.employeImage {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  object-fit: cover;
  padding: 0;
}

.cardImage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  margin-top: -50px;
  align-items: center;
}

.sec4CardContainer {
  max-width: 1200px;
  width: 100%;
  padding: 20px 10px;
  padding-top: 50px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.sec4Card {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 6px;
  border: 2px solid rgb(240, 240, 240);
  justify-content: space-evenly;
  height: 350px;
  max-height: 500px;
  max-width: 400px;
  width: 280px;
}

.sec4CardContentDiv1 {
  padding: 10px;
  height: 100%;
  text-align: center;
  margin-top: 70px;
  display: flex;
}

.sec4CardContentDiv2 {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  justify-content: center;
}
@media (max-width: 968px) {
  .employeImage {
    width: 85px;
    height: 85px;
  }
  .cardImage {
    margin-top: -40px;
  }
  .sec4Card {
    width: 250px;
    height: 300px;
    max-height: 350px;
    padding-top: 20px;
    margin-top: 40px;
  }
  .sec4CardContentDiv1 {
    margin-top: 40px;
  }
  .sec4CardContainer {
    padding-top: 4px;
  }
}
@media (max-width: 480px) {
  .employeImage {
    width: 75px;
    height: 75px;
  }
  .cardImage {
    margin-top: -40px;
  }
  .sec4Card {
    width: 220px;
    height: 250px;
    max-height: 350px;
    padding-top: 20px;
    margin-top: 40px;
  }
  .sec4CardContentDiv1 {
    margin-top: 30px;
  }
  .sec4CardContainer {
    padding-top: 4px;
  }
}
