.AboutSection4 {
  display: flex;
  flex-direction: column;
  background-color: rgb(242, 237, 245);
  height: fit-content;
  width: 100%;
  padding-top: 80px;
  align-items: center;
}

.grid-container {
  max-width: 1200px;
  padding: 0px 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.grid-item {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  flex-direction: column;
  margin: 1%;
  background-color: #ffffff9e;
  border-radius: 8px;
  padding: 20px;
  height: 250px;
  padding-top: 30px;
  text-align: center;
  width: 45%;
  transition: all 0.5s ease;
}
.grid-item:hover{
  transform: scale(1.06);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 
              0 1px 3px rgba(0, 0, 0, 0.08);
}

.aboutSection4Icon {
  font-size: 35px;
  position: absolute;
  top: 0;
  padding-top: 15px;
}

.grid-item h4 {
  margin-top: 40px;
  font-weight: bold;
}

@media (max-width: 1200px) {
  .grid-item {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .grid-item {
    width: 45%;
  }
  .grid-item h4 {
    margin-top: 30px;
  }
}

@media (max-width: 580px) {
  .grid-item {
    width: 70%;
    margin: 4px;
  }
  .aboutSection4Icon {
    font-size: 30px;
  }

  .grid-item h4 {
    margin-top: 10px;
    font-size: 1.2rem;
  }
}
