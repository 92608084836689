.ContactSection1 {
  width: 100%;
  height: 100vh;
  max-height: 1200px;
  background-image: url("../../../../assets/ContactPage/ContactSection1/image.png");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 10px;
}

.ContactSec1Content {
  display: flex;
  bottom: 20px;
  left: 20px;
  width: 100%;
  max-width: 1200px;
  color: #002b52;
  padding: 20px;
  border-radius: 10px;
  animation: fadeInUp 2.5s ease-in-out;
  flex-direction: column;
}

.ContactSec1Content h1 {
  margin: 0;
  font-size: 3em;
}

@keyframes fadeInUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@media (max-width: 1200px) {
  .ContactSec1Content {
    max-width: 1000px;
  }

  .ContactSec1Content h1 {
    font-size: 2.5em;
  }
}

@media (max-width: 992px) {
  .ContactSec1Content {
    max-width: 800px;
  }

  .ContactSec1Content h1 {
    font-size: 2em;
  }
}

@media (max-width: 768px) {
  .ContactSection1 {
  }

  .ContactSec1Content {
    max-width: 600px;
    padding: 15px;
    bottom: 10px;
    left: 10px;
  }

  .ContactSec1Content h1 {
    font-size: 1.8em;
  }
}

@media (max-width: 576px) {
  .ContactSec1Content {
    max-width: 100%;
    padding: 10px;
    bottom: 5px;
    left: 5px;
  }

  .ContactSec1Content h1 {
    font-size: 1.5em;
  }
}

@media (max-width: 400px) {
  .ContactSec1Content h1 {
    font-size: 1.2em;
  }
}
