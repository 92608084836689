.AboutSection1 {
  background-image: url("../../../../assets/AboutPage/AboutSection1/image.png");
  background-size: cover;
  display: flex;
  align-items: end;
  background-position: center;
  justify-content: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.AboutSec1Content {
  background-color: #f1eeffab;
  text-align: center;
  padding: 10px;
  width: 100vw;
}

.AboutSec1 h2,
.AboutSec1Content,
.AboutSec1 h4 {
  opacity: 0;
  animation: fadeIn 2s ease-in forwards;
}

.AboutSec1 h2 {
  animation-delay: 0.5s;
  font-size: 40px;
}

.AboutSec1 h4 {
  animation-delay: 1s;
  font-size: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(80px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1200px) {
  .AboutSec1Content {
    max-width: 900px;
    padding: 8px;
  }

  .AboutSec1 h2 {
    font-size: 36px;
  }

  .AboutSec1 h4 {
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  .AboutSec1Content {
    max-width: 750px;
    padding: 8px;
  }

  .AboutSec1 h2 {
    font-size: 32px;
  }

  .AboutSec1 h4 {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .AboutSec1Content {
    max-width: 600px;
    padding: 6px;
  }

  .AboutSec1 h2 {
    font-size: 28px;
  }

  .AboutSec1 h4 {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .AboutSection1 {
    align-items: end;
  }

  .AboutSec1Content {
    max-width: 100%;
    padding: 4px;
  }

  .AboutSec1 h2 {
    font-size: 24px;
    text-align: center;
  }

  .AboutSec1 h4 {
    font-size: 16px;
    text-align: center;
  }
}

@media (max-width: 400px) {
  .AboutSec1 h2 {
    font-size: 20px;
  }

  .AboutSec1 h4 {
    font-size: 12px;
  }
}
