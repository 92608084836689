.sec7sliderHandler {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  background-color: #f9f9f9;
}

.sec7MainDivSlider {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  text-align: center;
}

.sec7Heading h3 {
  font-size: 24px;
  color: #555;
  margin-bottom: 10px;
}

.sec7Heading h2 {
  font-size: 36px;
  color: #333;
  margin-bottom: 30px;
}

.sec7CardDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.HomeSec7Slider {
  display: flex;
  justify-content: center;
  align-items: center;
}
.HomeSec7Img {
  width: 100%;
  max-width: 500px;
  height: 250px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}
.sec7CardContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
}

.sec7Card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  height: 400px;
  max-height: 500px;
  margin: 10px;
}

.sec7ImgDiv {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.sec7Img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sec7CardContent {
  padding: 20px;
  text-align: left;
}

.sec7CardContent span {
  display: block;
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
}

.blogLink {
  display: flex;
  font-size: 18px;
  color: #333;
  line-height: 1.4;
  font-weight: 300;
  transition: color 0.3s;
}
.sec7CardContent a {
  text-decoration: none;
  text-align: center;
}

.blogLink:hover {
  color: #007bff;
  font-weight: 400;
  text-decoration: none;
}

@media (max-width: 480px) {
  .sec7Heading h2 {
    font-size: 28px;
  }

  .sec7Heading h3 {
    font-size: 20px;
  }

  .sec7CardContent {
    padding: 15px;
  }

  .blogLink {
    font-size: 16px;
  }
}
@media (max-width: 800px) {
  .HomeSec7Img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media (max-width: 600px) {
  .HomeSec7Img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .sec7ImgDiv {
    height: 200px;
    overflow: hidden;
  }
  .sec7Card {
    width: 100%;
    max-width: 300px;
    height: 400px;
    flex-direction: column;
    justify-content: center;
  }
}
