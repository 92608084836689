.sec2Heading {
  padding: 20px;
  text-align: center; 
}

.sec2MainDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px; 
}

.sec2ProjectDiv {
  display: flex;
  max-width: 1200px;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  gap: 10px; 
}

.sec2Div {
  border: 1px solid gainsboro;
  padding: 20px;
  max-width: 100%; 
  margin: 10px;
  background: linear-gradient(135deg, #b5e5ff, #ffffff);
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .sec2MainDiv {
    padding: 10px;
  }

  .sec2Div {
    padding: 15px; 
    max-width: 100%;
    margin: 10px;
  }
}

@media (max-width: 650px) {
    .sec2ProjectDiv {
    flex-direction: column; 
    padding: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .sec2Heading {
    padding: 10px; 
  }

  .sec2ProjectDiv {
    padding: 5px; 
    margin-bottom: 5px;
  }

  .sec2Div {
    padding: 10px; 
    margin: 5px;
  }
}
