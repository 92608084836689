* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header-navbar {
  width: 100%;
  background-color: rgb(255 255 255 / 60%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 1000;
}

.header-navbar-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  justify-content: space-between;
}

.logo img {
  height: 50px;
  border-radius: 50%;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); */
/* filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.759)); */
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  transition: all 0.3s ease;
}

.nav-links li {
  display: inline-block;
}

.nav-links a {
  color: #002c53;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #a4c8e2;
}

.nav-links a i {
  margin-right: 8px;
  font-size: 18px;
}

.menu-icon {
  display: none;
}
@media (max-width: 768px) {
  .nav-links {
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: -100%;
    width: 100%;
    background-color: rgb(255 255 255 / 60%);
    transition: left 0.3s ease;
    text-align: center;
  }

  .nav-links.show {
    left: 0;
  }

  .nav-links li {
    margin: 15px 0;
  }

  .nav-links a {
    font-size: 20px;
  }

  .header-navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .menu-icon {
    display: block;
    font-size: 24px;
    cursor: pointer;
    color: #002c53;
  }
}

@media (max-width: 480px) {
  .nav-links {
    gap: 10px;
    padding-left: 15px;
    top: 60px;
  }
  .nav-links a {
    padding-top: 10px;
    font-size: 14px;
  }

  .nav-links li {
    margin: 6px 0;
  }

  .menu-icon {
    font-size: 20px;
  }

  .logo img {
    height: 40px;
  }
}

.header-navbar.hide {
  top: -70px;
  animation: scrollDown 0.6s ease-in-out;
}
.header-navbar.show {
  top: -70px;
  animation: scrollUp 0.4s ease-in-out;
}
@keyframes scrollDown {
  0% {
    top: 0;
  }
  100% {
    top: -70px;
  }
}
@keyframes scrollUp {
  0% {
    top: -70px;
  }
  100% {
    top: 0;
  }
}

.header-navbar.show {
  top: 0;
}
