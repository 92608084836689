body {
  font-family: "Kumbh Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p,
li {
  font-size: 16px;
  font-family: "Kumbh Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: #666;
  line-height: 1.5;
}

h1 {
  font-size: 40px;
  font-family: "Outfit", sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 10px;
}

h2 {
  font-size: 30px;
  font-family: "Outfit", sans-serif;
  font-weight: 700;
  padding: 2px;
  font-style: normal;
  margin-bottom: 10px;
}

h3 {
  font-size: 28px;
  font-family: "Outfit", sans-serif;
  padding: 2px;
  font-weight: 700;
  margin-bottom: 10px;
}

h4 {
  padding: 2px;
  font-size: 24px;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  font-style: normal;
  margin-bottom: 10px;
}
textarea {
  font-family: "Outfit", sans-serif;
}
h5 {
  font-size: 20px;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  font-style: normal;
  margin-bottom: 10px;
  padding: 2px;
}

h6 {
  font-size: 18px;
  font-family: "Outfit", sans-serif;
  font-weight: 50;
  padding: 2px;
  font-style: normal;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  p,
  li {
    font-size: 14px;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  p,
  li {
    font-size: 12px;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 14px;
  }
}
