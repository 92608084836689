html,
body {
  height: 100%;
  margin: 0;
}
.social-icon-img {
}

.footerContainer {
  position: relative;
  transform: 1s all ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #333;
  color: #fff;
  padding: 20px;
  width: 100%;
}

.footerDiv1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
}

.footerDiv2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
  border-top: 1px solid #444;
}
.social-icon {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  gap: 10px;
}

.company-logo {
  filter: brightness(1.2);
}

.footerDivContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 25%;
  max-width: 300px;
  box-sizing: border-box;
}

.footerDivContainer ul {
  list-style: none;
  padding: 0;
}

.footerDivContainer a {
  color: #999;
  text-decoration: none;
}

.footerDivContainer a:hover {
  color: #fff;
  text-decoration: underline;
}
#footerEmail form input {
  background-color: transparent;
  padding: 10px;
  width: 100%;
  color: white;
  border-radius: 2%;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}
.linkedin:hover {
  color: #00a6ff;
}

#footerEmail form button {
  background-color: #444;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 2%;
  transition: background-color 0.3s ease;
}

#footerEmail form button:hover {
  background-color: #555;
}

@media (max-width: 768px) {
  .footerDivContainer {
    width: 100%;
    max-width: none;
    padding: 10px;
  }

  .footerDiv1 {
    flex-direction: column;
    align-items: center;
  }

  .footerDivContainer {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
  }

  .footerDivContainer ul {
    padding-left: 0;
  }

  .footerDivContainer a {
    display: block;
    margin: 5px 0;
  }
}
