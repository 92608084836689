.AboutSection5 {
  display: flex;
  padding: 2%;
  flex-direction: column;
  align-items: center;
}

.section5Container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 3%;
}

.section5ContainerDiv {
  display: flex;
  align-items: center;
  padding: 30px;
  flex-direction: column;
  background-color: #b3baca;
  width: 250px;
  height: 200px;
  justify-content: space-around;
  margin: 10px;
}

.section5ImgDiv {
  height: 60px;
  width: 120px;
}

@media (max-width: 1024px) {
  .section5Container {
    padding: 2%;
  }

  .section5ContainerDiv {
    width: 200px;
    height: 180px;
    padding: 20px;
  }

  .section5ImgDiv {
    height: 50px;
    width: 100px;
  }
}

@media (max-width: 768px) {
  .section5ContainerDiv {
    width: 180px;
    height: 160px;
    padding: 15px;
  }

  .section5ImgDiv {
    height: 40px;
    width: 80px;
  }
}

@media (max-width: 480px) {
  .section5Container {
    flex-direction: column;
    padding: 1%;
  }

  .section5ContainerDiv {
    width: 100%;
    height: auto;
    padding: 10px;
    margin: 5px 0;
  }

  .section5ImgDiv {
    height: 30px;
    width: 60px;
  }
}
