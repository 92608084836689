.iframeMap {
  height: 100%;
  width: 100%;
  max-height: 600px;
  height: 550px;
}

.responsiveMap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

