.MainContainerLogo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LogoContainer {
  border-bottom: 2px solid #ebebeb;
  display: flex;
  max-width: 1200px;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  /* border: 1px solid red; */
  flex-wrap: wrap;
  overflow: hidden;
  align-items: center;
  margin-top: 50px;
}
.brandImg {
  width: 200px;
  padding: 0px;
  object-fit: fill;
  transition: 0.4s ease-in-out;
}

.brandImgDiv {
  padding: 30px 50px;
}
.brandImg:hover {
  transform: scale(1.2);
}
@media (max-width: 600px) {
  .LogoContainer{
    justify-content: center;
  }
}
