.ServiceSection2 {
  display: flex;
  justify-content: center;
  padding: 25px;
  justify-content: center;
  width: 100%;
  min-height: 500px;
  background-color: #f2edf5;
  flex-direction: row;
}

.ServiceSection2Div1 {
  display: flex;
  flex-direction: column;
  top: 0;
  padding: 40px 10px;
  max-width: 100%;
  width: 600px;
  margin-right: 50px;
}

.ServiceSection2Div1 a:hover {
  color: #4678aa;
  text-decoration: underline;
}

.ServiceSection2Div1 a {
  color: #6b6a6a;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-decoration: none;
}

.ServiceSection2Div1 h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.ServiceSection2Div1 p {
  font-size: 16px;
  line-height: 1.6;
}
.ServiceSection2Div1 li {
  padding: 4px;
}
.ServiceSection2Div1 span {
  padding: 2px;
  color: rgba(72, 72, 72, 0.878);
  font-size: medium;
  font-weight: bold;
}
.ServiceSection2Div2 {
  display: flex;
  max-width: 100%;
  width: 600px;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ServiceSection2Div2 img {
  height: auto;
  width: 100%;
  max-width: 500px;
}

.ServiceSection2Div2Card {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  max-width: 500px;
  color: white;
  background-color: black;
  align-items: center;
  margin-bottom: 20px;
}

.ServiceSection2Div2Card img {
  height: 80px;
  width: 80px;
  margin-bottom: 20px;
}

.ServiceSection2Div2Card h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.ServiceSection2Div2Card p {
  font-size: 16px;
  line-height: 1.6;
}

@media (max-width: 1024px) {
  .ServiceSection2Div1 {
    max-width: 350px;
    margin-right: 0;
    padding: 10px;
  }
    .ServiceSection2Div2Card img {
  height: 60px;
  width: 60px;
  margin-bottom: 0px !important;
}
.ServiceSection2Div2Card p{
  font-size: 14px;
}
.ServiceSection2Div2Card h4{
  font-size: 22px;
}

}

@media (max-width: 768px) {
  .ServiceSection2 {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }

  .ServiceSection2Div1 {
    margin-bottom: 20px;
    max-width: 100%;
  }

  .ServiceSection2Div2 {
    align-items: center;
    width: 100%;
  }

  .ServiceSection2Div2 img {
    max-width: 100%;
  }

  .ServiceSection2Div2Card {
    width: 100%;
  }
  .ServiceSection2Div2Card img {
  height: 40px;
  width: 40px;
  margin-bottom: 0px !important;
}
.ServiceSection2Div2Card p{
  font-size: 10px;
}
.ServiceSection2Div2Card h4{
  font-size: 18px;
}
}
