.AboutSec2MainDiv {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  padding: 20px;
  background-color: rgb(245 245 245);
}

.AboutSec2Div {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  padding: 10px;
}

.AboutSec2text-content {
  padding: 0px 20px 0 30px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.AboutSec2text-content h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.AboutSec2text-content p {
  font-size: 1rem;
  margin-bottom: 20px;
}

.progress-bar {
  margin-bottom: 20px;
}

.progress-bar span {
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
}

.progress {
  border-radius: 5px;
  overflow: hidden;
}

.progress-fill {
  background-color: #a5c9e2;
}

.AboutSec2Image img {
  max-width: 100%;
  height: auto;
  object-fit: fill;
}

@media (max-width: 768px) {
  .AboutSec2MainDiv {
    flex-direction: column;
  }
  .AboutSec2Div {
    flex-direction: column;
  }

  .AboutSec2text-content h1 {
    font-size: 1.5rem;
  }

  .AboutSec2text-content p {
    font-size: 0.9rem;
  }

  .progress-bar span {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .AboutSec2text-content h1 {
    font-size: 1.2rem;
  }

  .AboutSec2text-content p {
    font-size: 0.8rem;
  }

  .progress-bar span {
    font-size: 0.8rem;
  }
}
