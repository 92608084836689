.Section1MainContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.Section1Container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.div1,
.div2 {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

.div1 .imageDiv {
  display: flex;
  justify-content: center;
}

.div1 .image1 {
  max-width: 100%;
  height: auto;
}

.sec1Heading h6,
.sec1Heading h2 {
  margin: 0;
}

.sec1Heading h6 {
  font-size: 16px;
}

.sec1Heading h2 {
  font-size: 32px;
}

.sec1Text h4 {
  margin-top: 20px;
  font-size: 24px;
}

.sec1Text p {
  margin: 10px 0;
  font-size: 16px;
}
@media (max-width: 1000px) {
  .Section1Container {
  }
  .sec1Heading h2 {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .Section1Container {
    flex-direction: column;
  }

  .div1,
  .div2 {
    width: 100%;
    padding: 10px;
  }

  .sec1Heading h2 {
    font-size: 22px;
  }

  .sec1Text h4 {
    font-size: 20px;
  }

  .sec1Text p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .sec1Heading h2 {
    font-size: 20px;
  }

  .sec1Text h4 {
    font-size: 18px;
  }

  .sec1Text p {
    font-size: 12px;
  }
}
