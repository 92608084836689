.container {
  text-align: center;
  color: white;
  height: 100vh;
  display: flex;
  justify-content: left;
  align-items: center;
  background-size: cover;
  background-position: center;
}

.ContainerText {
  animation: fadeInLeft 2.5s ease-in-out;
  padding: 20px;
  text-align: left;
  max-width: 600px;
  border-radius: 8px;
}

.heading {
  font-size: 2.5rem;
  line-height: 1.2;
  margin: 0;
  font-weight: 700;
}

.heading .highlight {
  color: #ffd700;
  font-weight: bold;
}

.subheading {
  font-size: 1.2rem;
  margin-top: 10px;
  color: #e0e0e0;
}


@keyframes fadeInLeft {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 1200px) {
  .heading {
    font-size: 2.2rem;
  }

  .subheading {
    font-size: 1.1rem;
  }
}

@media (max-width: 992px) {
  .heading {
    font-size: 2rem;
  }

  .subheading {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 1.8rem;
  }

  .subheading {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .heading {
    font-size: 1.5rem;
  }

  .subheading {
    font-size: 0.8rem;
  }
}
