.ServiceSection3MainDiv {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.ServiceSection3 {
  padding: 30px;
  display: flex;
  max-width: 1200px;
  flex-direction: column;
}

.ServiceSection3Div1 {
  display: flex;
  flex-direction: column;
}

.ServiceSection3Div2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  overflow: hidden;
}

.serviceSection3Card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(50% - 10px);
  border: 1px solid #ebebeb;
  margin-bottom: 20px;
  transition: 0.3s ease-in-out;
}
.serviceSection3CardImage {
  width: 100%;
  overflow: hidden;
}

.serviceSection3CardImage img {
  object-fit: fill;
  transition: 0.3s ease-in-out;
  width: 100%;
}

.serviceSection3CardContent {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  background-color: white;
  border-top: none;
  overflow: hidden;
  padding: 10px;
}
.serviceSection3Card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  transform: scale(1.02);
}

.serviceSection3CardImage img:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .serviceSection3Card {
    width: 100%;
  }
}
