.contact-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 40px;
  background-color: hwb(213.33deg 81.18% 15.29% / 30%);
}

.contact-item {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.contact-content {
  /* border: 2px solid red; */
  max-width: 600px;
  width: 100%;
  padding: 20px;
  background-color: white;
  flex: 1;
   background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-right: 20px;
}

.contactUsItem {
  display: flex;
  overflow: hidden;
}

.contact-content h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.contact-content p {
  font-size: 1.1rem;
  margin-bottom: 30px;
}

.contact-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.contact-item {
  display: flex;
  left: 0;
  justify-content: left;
  align-items: start;
  width: 100%;
}

.icon {
  font-size: 1.3rem;
  padding-left: 4px;
}

.contact-item h5 {
  margin: 0 0 5px;
  font-size: 1.1rem;
}

.contact-item p {
  margin: 0;
  font-size: 1rem;
}

.contact-form-container {
  flex: 1;
  max-width: 600px;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.contact-form-container h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.contact-form-container p {
  font-size: 1rem;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

textarea {
  resize: none;
  color: black;
  height: 100px;
}

button[type="submit"] {
  padding: 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .contact-section {
    flex-direction: column;
    padding: 20px;
    align-items: center;
  }

  .contact-content,
  .contact-form-container {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .contact-content h1 {
    font-size: 2rem;
  }

  .contact-form-container h2 {
    font-size: 1.3rem;
  }

  .form-group {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .contact-section {
    padding: 10px;
  }

  .contact-content h1 {
    font-size: 1.5rem;
  }

  .contact-form-container h2 {
    font-size: 1.1rem;
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.9rem;
  }

  button[type="submit"] {
    font-size: 0.9rem;
  }

  .contact-content p,
  .contact-item h5,
  .contact-item p,
  .contact-form-container p {
    font-size: 0.9rem;
  }

  .icon {
    font-size: 1.5rem;
  }
}
