.HomeSec3Slide-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  background-color: #f9f9f9;
}

.HomeSec3SliderMainDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  text-align: center;
}

.HomeSec3SliderHeading h1 {
  font-size: 36px;
  color: #333;
  margin-bottom: 30px;
}
.SliderImgDiv {
  margin: 2px;
  padding: 6px 0px;
}

.HomeSec3SliderDiv {
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slide-image {
  width: 100%;
  max-width: 550px;
  height: 250px;
  border-radius: 10px;
  box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.1);
  object-fit: fill;
}

@media (max-width: 2400px) {
  .slide-image {
    max-width: 360px;
    height: 200px;
  }
}
@media (max-width: 1500px) {
  .slide-image {
    max-width: 330px;
    height: 180px;
  }
}
@media (max-width: 1024px) {
  .slide-image {
    max-width: 300px;
    height: 160px;
  }
}
@media (max-width: 900px) {
  .slide-image {
 height: 140px;
    max-width: 240px;
  }
}

@media (max-width: 768px) {
  .slide-image {
    height: 140px;
    max-width: 240px;
  }
}

@media (max-width: 480px) {
  .slide-image {
    height: 130px;

    max-width: 220px;
  }
}
