.ServiceSection1 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100vh;
  background-image: url("../../../../assets/ServicePage/ServiceSection1/image.png");
  background-size: cover;
  background-position: center;
  justify-content: center;
  background-repeat: no-repeat;
  padding: 20px;
  color: white;
}

.ServiceSec1Content h2 {
  max-width: 1200px;
  width: 70%;
  display: flex;
  align-items: center;
  font-size: 3rem;
  animation: fadeInUp 2.5s ease-in-out;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@media (max-width: 1200px) {
  .ServiceSec1Content h2 {
    font-size: 2.5rem;
  }
}

@media (max-width: 992px) {
  .ServiceSec1Content h2 {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .ServiceSec1Content h2 {
    font-size: 1.75rem;
  }
}

@media (max-width: 576px) {
  .ServiceSec1Content h2 {
    font-size: 1.5rem;
  }
}
